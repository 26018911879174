<template>
	<vue-editor
		:value="value"
		@input="(value) => $emit('input', value)"
	></vue-editor>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
	name: "MwEditor",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
		};
	},
	components: {
		VueEditor,
	},
};
</script>

export default {
    user: {
        value: "user", 
        display: true, 
        default: "" 
    },
    organisation: {
        value: "organisation", 
        display: true, 
    },
    tags: {
        value: "tags", 
        display: true, 
        label: "Tags",
        default: []
    },
    method: {
        value: "method", 
        display: true, 
        component: "v-select", 
        label: "Method", 
        default: "",
        items: ["Contact Form", "Email", "Live Chat", "Other"]
    },
    status: {
        default: "",
        value: "status", 
        display: true, 
        component: "v-select", 
        label: "Status", 
        items: ["Open", "Investigating", "Follow-up", "Escalated", "Resolved", "Closed Unresolved"]
        
    },
    notes: {
        value: "notes", 
        display: true, 
        component: "v-textarea", 
        label: "Notes", 
    },
}
export default {
    subject: {
        value: "subject", 
        display: true,
        component: "v-text-field",
        label: "emailTemplates.field.subject"
    },
    html: {
        value: "html", 
        display: true, 
        label: "emailTemplates.field.html"
    },
}
import auth from "./modules/auth.js";
import config from "./modules/config.js";
import indicators from "./modules/indicators.js";
import questions from "./modules/questions.js";
import questionTemplates from "./modules/questionTemplates.js";
import users from "./modules/users.js";
import assessments from "./modules/assessments.js";
import organisations from "./modules/organisations.js";
import sections from "./modules/sections.js";
import emails from "./modules/emails.js";
import emailTemplates from "./modules/emailTemplates.js";
import content from "./modules/content.js";
import supportLog from "./modules/supportLog.js";
import knowledgeBase from "./modules/knowledgeBase.js";
import categoryOptions from "./modules/categoryOptions.js";
import categories from "./modules/categories.js";
import sectionResponses from "./modules/sectionResponses.js";

export default {
    assessments,
    auth,
    categoryOptions,
    categories,
    config,
    content,
    emails,
    emailTemplates,
    indicators,
    knowledgeBase,
    questions,
    questionTemplates,
    organisations,
    users,
    sections,
    sectionResponses,
    supportLog
};

<template>
    
    <div>
        No additional options
    </div>
</template>

<script>
export default {
    name: "Content", 
    components: {
    }
}
</script>

<template>
	<v-sheet flat v-if="user">
		<h5>Stakeholder Permissions</h5>
		<v-switch
			:label="$t('users.fields.is_stakeholder')"
			:input-value="isStakeholder"
			@change="(v) => setPermission('admin', v)"
		></v-switch>
		<template v-if="isStakeholder">
			<v-switch
				:label="$t('users.fields.is_universal')"
				:input-value="isUniversal"
				@change="(v) => setPermission('universal', v)"
			></v-switch>
			<v-select
				multiple
                chips
                :label="$t('users.fields.stakeholder_organisations')"
				v-if="isStakeholder && !isUniversal"
				:items="orgItems"
				:value="user.permissions.limit"
				@change="(v) => setPermission('limit', v)"
			>
			</v-select>
		</template>
	</v-sheet>
</template>

<script>
export default {
	name: "EditStakeholderPermissions",
	props: {
		id: { type: String },
	},
	computed: {
		user() {
			return this.$store.state.users.data[this.id];
		},
        organisations() {
            return this.$store.state.organisations.data
        },
        orgItems(){
            return this.mwutils.itemsArray(this.organisations)
        },
		isStakeholder() {
			if (!this.user) {
				return false;
			} else {
				return this.user.permissions && this.user.permissions.admin;
			}
		},
		isUniversal() {
			if (!this.user) {
				return false;
			} else {
				return this.user.permissions && this.user.permissions.universal;
			}
		},
	},
	methods: {
		setPermission(key, value) {
			this.$store.dispatch("users/patch", {
				id: this.id,
				permissions: { [key]: value },
			});
		},
	},
};
</script>

<template>
	<v-sheet>
		<mw-list storeModule="knowledgeBase" :columns="headers" path="knowledge">
			<template v-slot:item.question="slotProps">
				<span v-if="slotProps.question" v-html="`${slotProps.question.substr(0,100)}...`"></span>
			</template>
			<template v-slot:item.answer="slotProps">
				<span v-if="slotProps.answer" v-html="`${slotProps.answer.substr(0,100)}...`"></span>
			</template>
		</mw-list>
		<import storeModule="knowledgeBase" defaultFields="question, answer, status"></import>
	</v-sheet>
</template>


<script type="text/javascript">
import Import from "@c/buttons/Import";
import MwList from "@c/ui/MwList";
export default {
	name: "Knowledge",
	computed: {
		headers() {
			return [
				{ value: "question", text: "Question", default: true },
				{ value: "answer", text: "Answer", default: true },
				{ value: "status", text: "Status", default: true },
			];
		},
	},
	components: {
		MwList,
		Import,
	},
};
//
</script>
"

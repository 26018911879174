<template>
	<div>
		<v-simple-table>
			<thead>
				<tr>
					<th width="20%"></th>
					<th></th>
					<slot name="appendHeaders"></slot>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(doc, i) in docs" :key="i">
					<td>
						<v-icon class="mr-5" color="red" @click="deleteItem(doc.id)">mdi-close-circle</v-icon>
						<v-icon @click="move(doc.id, 'up')">
							mdi-arrow-up-drop-circle</v-icon
						>
						<v-icon @click="move(doc.id, 'down')">
							mdi-arrow-down-drop-circle
						</v-icon>
					</td>
					<td>
						{{ doc[itemText] }}
						<v-btn icon
							router-link
							target="_blank"
							:to="`/${storeModule}/${doc.id}`"
						>
							<v-icon>mdi-open-in-new</v-icon>
						</v-btn>
					</td>
					<slot name="append" :id="doc.id"></slot>
				</tr>
			</tbody>
			
		</v-simple-table>
		<v-row class="mt-5">
			<mw-select-or-create
				class="mt-4"
				v-model="add"
				v-bind="$props"
				:label="$t('config.fields.add_new_section')"
				:multiple="false"
			>
			</mw-select-or-create>
		</v-row>
	</div>
</template>

<style lang="less" scoped> 
	.v-chip {
		cursor: pointer
	}
</style>

<script>
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";

export default {
	name: "MwSelectAndOrder",
	props: {
		value: { type: Array, default: () => [] }, // @doc the array of values
		storeModule: { type: String }, // the module of the store to select from and add to
		filter: { type: Array }, // an array of IDs to include
		exclude: { type: Array }, // an array of IDs to exclude,
		label: { type: String }, //
		itemText: { type: String, default: "name" }, // the key to use for the item label
	},
	components: {
		MwSelectOrCreate,
	},
	computed: {
		collection() {
			return this.$store.state[this.storeModule].data;
		},
		docs() {
			var self = this;
			return self.value.map((a) => self.collection[a]).filter(a => a);
		},
	},
	data: () => {
		return {
			add: "",
		};
	},
	watch: {
		add(value) {
			if (value) {
				this.$emit("input", [...this.value, value]);
				this.add = null;
			}
		},
	},
	methods: {
		deleteItem(id) {
			var values = [...this.value];
			var index = values.indexOf(id);
			values.splice(index, 1);
			this.$emit("input", values);
		},
		move(id, direction) {
			var values = [...this.value];
			this.mwutils.moveItemInArray(id, values, direction);
			this.$emit("input", values);
		},
	},
};
</script>

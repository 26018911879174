<template>
	<v-dialog v-model="dialog" width="560">
		<template v-slot:activator="{ on }">
			<div class="button" v-on="on" >
				<span class="pr-2 pl-2" v-if="questionTemplate">{{ questionTemplate.name }}</span>
				<v-icon v-else>mdi-plus-circle</v-icon>
			</div>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-autocomplete :items="items" v-model="value">
					<template v-slot:append v-if="value">
						<v-btn icon	router-link target="_blank" :to="`/questionTemplates/${value}`">
							<v-icon>mdi-open-in-new</v-icon>
						</v-btn>
					</template> 

				</v-autocomplete>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false">{{
					$t("buttons.cancel")
				}}</v-btn>
				<v-btn color="primary" @click="submit">{{
					$t("buttons.submit")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script type="text/javascript">
export default {
	name: "SelectQuestionTemplates",
	props: {
		id: { type: String }, // @doc the id of the question
	},
	data: () => {
		return {
			dialog: false,
			value: "",
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		questionTemplate() {
			if (!this.question || !this.question.question_template) {
				return false;
			}
			return this.questionTemplates[this.question.question_template];
		},
		items() {
			return this.mwutils.itemsArray(this.questionTemplates);
		},
	},
	watch: {
		questionTemplate: {
			immediate: true,
			handler(q) {
				if( q ){
					this.value = q.id;
				}
			},
		},
	},
	methods: {
		submit() {
            var self = this;
            self.$store.dispatch("questions/patch", {id: self.id, question_template: self.value}).then( () => {
                self.dialog = false;
            })
        },
	},
};
</script>

export default {     
	displayName: {
		value: "displayName",
		default: "",
		display: true,
        component: "v-text-field",
		label: "users.fields.displayName",
	},
	email: {
		value: "email",
		default: "",
		display: true,
        component: "v-text-field",
		label: "users.fields.email",
	},
    organisations: {
		value: "organisations",
		default: [],
		display: true,
		label: "users.fields.organisations",
	}, 
	status: {
		value: "status", 
		default: "", 
		display: true, 
		component: "v-select", 
		label: "Status", 
		items: ["published", "replaced", "updated", "new", "pending"]
	}, 
	screenshare_support_available: {
		value: "screenshare_support_available", 
		label: "Allow to share screen", 
		display: false,
		
	}
}

<template>
	<v-dialog :width="360" v-model="dialog">
		<template v-slot:activator="{on}">
			<v-btn class="ml-1" small v-on="on" color="primary" dark>{{$t("buttons.copy")}}</v-btn>	
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				{{$t("misc.are_you_sure")}}
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="deleteObject" color="primary" dark>{{$t("buttons.copy")}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "Copy", 
		props: {
			id: { type: String }, // @doc the ID of the object 
			storeModule: { type: String }, // the corresponding vuex module
			path: {  type: String  } // the path for the url - almost always the same as the storeModule
		},
		data: () => {
			return {
				dialog: false
			}
		},
		methods: {
			deleteObject(){
				const self = this;
				self.$store.dispatch(`${self.storeModule}/duplicate`, self.id ).then(() => {
					self.dialog = false;
				})
			}
		}

	}
// </script>"
<template>
	<v-sheet flat>
		<template v-for="field in fields">
			<!-- if the field has a named slot -->
			<slot
				v-if="haveSlot(field.value)"
				:name="field.value"
				:data="formData"
			></slot>
			<!-- otherwise parse as standard -->
			<mw-form-field
				v-else
				:data="formData[field.value]"
				:key="field.value"
				@update="(value) => (formData[field.value] = value)"
				v-bind="model[field.value]"
			></mw-form-field>
		</template>
		<slot name="append"></slot>
		<p class="text-right">
			<v-btn color="primary" @click="save">{{ $t("buttons.save") }}</v-btn>
		</p>
		<v-dialog v-model="error" width="360">
			<v-card>
				<v-card-title>{{ $t("errors.error") }}</v-card-title>
				<v-card-text>
					{{ $t("errors.not_found") }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn router-link color="primary" :to="`/${path || storeModule }/new`">{{
						$t("buttons.new")
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<saved v-model="saved"></saved>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Saved from "@/components/messages/Saved";
import MwFormField from "@c/ui/MwFormField";

export default {
	name: "Edit",
	props: {
		id: { type: String }, // the id of the document
		path: {type: String }, // where the path is different to the store module
		mode: { type: String, default: "collection" }, // collection or doc mode
		storeModule: { type: String }, // the storeModule id
		embedded: { type: Boolean, default: false }, // if a form is embedded it emits the ID instead of redirecting to another page
		prefill: { type: Object }, //  key/value pairs of data to prefill into the form where required
		model: { type: Object }, // @doc the data model of the object - for generating fields, labels and default values
	},
	data: () => {
		return {
			saved: false,
			formData: false,
			error: false,
		};
	},
	components: {
		MwFormField,
		Saved,
	},
	computed: {
		fields() {
			var self = this;
			// @doc retrieves only fields you want to display
			var fields = self.mwutils.getFields(this.model);
			// @doc gets the translated field label
			return Object.fromEntries(
				fields.map((field) => {
					field.label = self.$t(field.label);
					return [field.value, field];
				})
			);
		},
		doc() {
			// @doc retrieves the object from the database or a new empty document
			var doc;
			if (this.mode == "doc") {
				doc = this.$store.state[this.storeModule].data;
			} else {
				doc = this.$store.state[this.storeModule].data[this.id];
			}
			doc = doc || this.mwutils.getEmpty(this.model);
			return doc;
		},
	},
	watch: {
		id(value) {
			if (!value) {
				this.error = false;
			}
		},
	},
	methods: {
		haveSlot(key) {
			return typeof this.$scopedSlots[key] !== "undefined";
		},
		loadEmptyForm() {
			const self = this;
			var empty = this.mwutils.getEmpty(this.model);
			for (var p in this.prefill) {
				empty[p] = this.prefill[p];
			}
			self.formData = empty;
		},
		loadFormData() {
			const self = this;
			for (var key in self.formData) {
				if (typeof self.doc[key] !== "undefined") {
					self.formData[key] = self.doc[key];
				}
			}
		},
		save() {
			const self = this;
			var document = { ...self.formData };
			for (var key in document) {
				if (typeof document[key] == "undefined") {
					delete document[key];
				}
			}
			if (self.id) {
				document.id = self.id;
			}
			self.$store
				.dispatch(`${self.storeModule}/set`, document)
				.then((res) => {
					if (res !== self.id) {
						if (self.embedded) {
							self.$emit("saved", res);
						} else {
							self.$router.replace(`/${self.path || self.storeModule}/${res}`);
						}
					} else {
						self.saved = true;
					}
				});
		},
	},
	created() {
		const self = this;
		// load empty form data from visible fields
		self.loadEmptyForm();
		if (self.mode == "doc") {
			self.$store
				.dispatch(`${self.storeModule}/openDBChannel`)
				.then(() => {
					self.loadFormData();
				})
		} else {
			if (self.id) {
				self.$store
					.dispatch(`${self.storeModule}/fetchById`, self.id)
					.then(() => {
						self.loadFormData();
					})
					.catch(() => {
						self.error = true;
					});
			}
		}
	},
};
//
</script>
"

<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="emailTemplates"
			:model="model"
			@saved="$emit('saved')"
		>
			<template v-slot:html="{ data }">
				<mw-editor v-model="data.html"></mw-editor>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import EmailTemplate from "@/models/EmailTemplate";
import MwEdit from "@c/ui/MwEdit";
import MwEditor from "@c/ui/MwEditor";


export default {
	name: "EditEmailTemplate",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return EmailTemplate;
		},
	},
	components: {
		MwEdit,
        MwEditor
    },
};
//
</script>
"

<template>
    <tr>
        <slot name="prepend"></slot>
        <td>
            <v-select :items="sections" v-model="section" @click="fetchSections"></v-select>
        </td>
        <td>
            <v-select v-if="section" :items="questions" v-model="question"></v-select>
        </td>
        <td>
            <v-select v-if="question"  :items="Object.keys(operators)" v-model="operator"></v-select>
        </td>
        <td>
            <v-select v-if="operator"  :items="options" v-model="value" :multiple="operators[operator].multiple"></v-select>
        </td>
    </tr>
</template>

<script>
export default {
    name: "QuestionVisibility", 
    props: {
        rule: { type: Object }
    }, 
    data: () => {
        return {
            section: null,
            question: null,
            operator: null, 
            value: null,
            types: ["question", "category"] ,			
            operators: {
				"!=": {
					multiple: false,
                    has_answer: true
				},
				in: {
					multiple: true, 
                    has_answer: true
				},
				"==": {
					multiple: false, 
                    has_answer: true
				},
			},

        }
    },
	computed: {
		sections() {
			let sections = this.$store.state.sections.data;
			return this.mwutils.itemsArray(sections);
		},
		questions() {
            const self = this;
            let questions = [{value: "any", text: "Any"}];
			if (self.section) {
                let section = self.$store.state.sections.data[self.section];
                if( section.questions ){
                   section.questions.forEach((q) => {
                        questions.push( {
                            value: q,
                            text: self.$store.getters["questions/getQuestionTexts"](q).string,
                        } );
                    });
                }
			}
            return questions;
		},
		options() {
			if (!this.question || this.question == "any") {
				return ["no-answer"];
			}
            
			let options = this.$store.getters["questions/getAnswerOptions"](
				this.question
			);
			return this.mwutils.itemsArray(options, "text");
		},
        output(){
			return {
				operator: this.operator,
				identifier: `${this.section}.${this.question}`,
				value: this.value,
            }
        }

    },
    methods: {		
        fetchSections() {
			this.$store.dispatch("sections/openDBChannel")
		},
    },
    watch: {
        output(value){
            this.$emit("input", value)
        }
    }, 
    created(){
		if (this.rule) {
			if (this.rule.identifier) {
				let identifiers = this.rule.identifier.split(".");
				this.section = identifiers[0];
				this.question = identifiers[1];
			}
			if (this.rule.value) {
				this.answer = this.rule.value;
			}
			if (this.rule.operator) {
				this.operator = this.rule.operator;
			}
		}
    }
}
</script> 
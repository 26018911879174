export default {     
	firestorePath: "questionTemplates",
	firestoreRefType: "collection",
	moduleName: "questionTemplates",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-frequently-asked-questions",
		name: {
			single: "Question",  
			plural: "Questions"
		},
		path: "/questionTemplates"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

<template>
	<v-sheet>
        <MwList storeModule="users" :columns="headers">
        </MwList>
    </v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
export default {
	name: "Users",
    data: () => {
        return {
            filtered: false
        }
    },
	computed: {
		headers() {
			return [ 
				{value: "displayName", text: "Name", default: true},
				{value: "email", text: "Email", default: true},
				{value: "status", text: "Status", default: true},
			];
		},
	},
	components: {
		MwList
	},
};
</script>


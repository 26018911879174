<template>
	<v-sheet>
        <MwList storeModule="categories" :columns="headers">
        </MwList>
    </v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
export default {
	name: "Categories",
    data: () => {
        return {
            filtered: false
        }
    },
	computed: {
		headers() {
			return [
				{value: "name", text: "Name", default: true},
			];
		},
	},
	components: {
		MwList
	},
};
//
</script>
"

<template>
	<span>
		<v-component
			:is="component"
			:label="$t(label)"
			v-model="value"
			:items="component == 'v-select' ? items : false"
			v-bind="options"
		></v-component>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vue from "vue";

import { VTextField, VCheckbox, VTextarea, VSelect, VColorPicker } from "vuetify/lib";

Vue.component("VTextField", VTextField);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VTextarea", VTextarea);
Vue.component("VSelect", VSelect);
Vue.component("VColorPicker", VColorPicker);

export default {
	name: "GeneralField",
	props: {
		component: {},
		label: {},
		options: {},
		items: {},
		data: {},
	},
	data: () => {
		return {
			value: "",
		};
	},
	watch: {
		value() {
			this.$emit("update", this.value);
		},
		data: {
			immediate: true,
			handler() {
				if (this.value !== this.data) {
					this.value = this.data;
				}
			},
		},
		// this.ready = true
	},
};
//
</script>
"

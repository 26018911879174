<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="knowledgeBase"
			:model="model"
			path="knowledge"
			@saved="$emit('saved')"
		>	
		<template v-slot:tags="slotProps">
				<mw-select-or-create
					v-model="slotProps.data.tags"
					storeModule="categoryOptions"
					:multiple="true"
				>
				</mw-select-or-create>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less">
.ql-editor {
	max-height: 600px;
	margin-bottom: 20px;
}
</style>

<script type="text/javascript">
import Knowledge from "@/models/Knowledge";
import MwEdit from "@c/ui/MwEdit";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";

export default {
	name: "EditSupport",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Knowledge;
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate
	},
};
//
</script>
"

export default {
    name: {
        value: "name", 
        component: "v-text-field",
        default: "", 
        display: true, 
        label: "misc.name", 
    },
    nicename: {
        value: "nicename", 
        component: "v-text-field",
        default: "", 
        display: true, 
        options: {
            disabled: true,
            hint: "A slug"
        },
        label: "Nicename"
    },
    description: {
        value: "description",
        component: "v-textarea",
        default: "", 
        display: true, 
        label: "misc.description"
    },
    color: {
        value: "color", 
        default: "", 
        component: "v-color-picker",
        display: true, 
        options: {
            // "hide-canvas": true,
            mode: "hexa"
        },
        label: "misc.color"
    },
    position: {
        value: "position",
        default: 0, 
        component: "v-text-field",
        options: {
            type: "number"
        },
        label: "misc.position",
        display: true, 
    },
}
<template>
	<v-sheet flat>
		<v-tabs v-model="tab">
			<v-tab>Info</v-tab>
			<v-tab v-if="id">Questions</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<mw-edit :id="id" storeModule="sections" :model="model">
					<template v-slot:theme="slotProps">
						<mw-select-or-create
							v-if="theme"
							v-model="slotProps.data.theme"
							storeModule="categoryOptions"
							:include="theme.options"
							@input="checkChange"
							:multiple="false"
						>
						</mw-select-or-create>
					</template>
					<template v-slot:introduction="slotProps">
						<mw-editor
							v-model="slotProps.data.introduction"
						>
						</mw-editor>
					</template>
					<template v-slot:introductions="slotProps">
						<p v-for="service in services" :key="service.id" class="mt-5">
							{{service.name}} Introduction 
						<mw-editor 
							v-model="slotProps.data.introductions[service.id]"
						>
						</mw-editor>
						</p>
					</template>
				</mw-edit>
			</v-tab-item>
			<v-tab-item>
				<edit-questions :id="id"></edit-questions>
			</v-tab-item>
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less">
.v-tabs {
	margin-bottom: 15px;
	.v-tab {
		text-transform: none;
		font-weight: normal;
	}
}
</style>

<script type="text/javascript">
import { arrayUnion } from "vuex-easy-firestore";
import Section from "@/models/Section";
import MwEdit from "@c/ui/MwEdit";
import MwEditor from "@c/ui/MwEditor";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";
import EditQuestions from "@/components/edits/EditQuestions";

export default {
	name: "EditSection",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
			tab: 0,
		};
	},
	computed: {
		model() {
			return Section;
		},
		theme() {
			return this.$store.state.categories.data.theme;
		},
		services() {
			return this.$store.getters["categoryOptions/services"];
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate,
		EditQuestions,
		MwEditor
	},
	methods: {
		checkChange(value) {
			if (!this.theme.options.includes(value)) {
				this.$store.dispatch("categories/patch", {
					id: "theme",
					options: arrayUnion(value),
				});
			}
		},
	},
	created() {
		this.$store.dispatch("categories/fetchById", "theme");
		this.$store.dispatch("categories/fetchById", "service");
	},
};
</script>

export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
	},
	mutations: {},
	actions: {
        reminder({rootGetters, dispatch}, id ){
            var mail = {
                toUids: [id], 
                template: {
                    data: {},
                    name: "reminder", 
                }, 
                assessment: rootGetters["assessment/id"]

            }
            return dispatch("insert", mail )
        }, 
        delegate({rootGetters, dispatch}, id ){
            var mail = {
                toUids: [id], 
                template: {
                    data: {},
                    name: "delegate", 
                }, 
                assessment: rootGetters["assessment/id"]
            }
            return dispatch("insert", mail )
        }, 
        leadRespondent({rootGetters, dispatch}, id ){
            var mail = {
                toUids: [id], 
                template: {
                    data: {},
                    name: "leadRespondent", 
                }, 
                assessment: rootGetters["assessment/id"]
            }
            return dispatch("insert", mail )
        }, 
		poll({rootGetters, rootState, dispatch}, id ){
			var poll = rootState.polls.data[id];
            var mail = {
                toUids: [poll.user], 
                poll: id,
                template: {
                    data: {},
                    name: "newpoll", 
                }, 
                assessment: rootGetters["assessment/id"]

            }
            return dispatch("insert", mail )
        }
    },
};

export default {
    category: {
        value: "category", 
        display: true,
        default: ""
    }, 
    value: {
        value: "value", 
        display: true,
        default: ""
    },
    operator: {
        value: "operator", 
        display: true,
        default: "" ,
        items: ["in", "=", "!=", ">", "<"]
    },  

}
export default {
	firestorePath: "sectionResponses",
	firestoreRefType: "collection",
	moduleName: "sectionResponses",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [
			["status", "in", ["not_started", "delegated", "complete", "revoked", "returned", "in_progress"]	]
		], 
		defaultValues: {
			status: "not_started"
		},
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};

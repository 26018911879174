import firebase from "firebase";
import Vue from "vue";
export default {
	firestorePath: "users/{userId}",
	firestoreRefType: "doc",
	moduleName: "auth",
	statePropName: "data",
	namespaced: true,
	state: {
		logged_in: false,
		logged_out: false,
	},
	sync: {
		defaultValues: {
			role: "user",
		}
	},
	getters: {
		id( state ) {
			return state.data.id;
		},
        isAdmin(state){
            return state.data.role == "superadmin" || state.data.role == "admin"
        },
	},
	mutations: {
		SET_LOGGED_IN(state) {
			Vue.set(state, "logged_in", new Date());
		},
		SET_LOGGED_OUT(state) {
			Vue.set(state, "logged_in", false);
			Vue.set(state, "logged_out", new Date());
		},
	},
	actions: {
		start({ dispatch, commit, getters }) {
			return dispatch("openDBChannel").then(() => {
				if( getters.isAdmin ){
					dispatch("start", false, {root: true})
                    commit("SET_LOGGED_IN")
                    return true
                }
                else{
                    dispatch("stop");
                    throw new Error("not-admin");
                }
			});
		},
		stop({ commit, dispatch }) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					commit("SET_LOGGED_OUT");
					dispatch("clearAll", false, { root: true });
					commit("RESET_VUEX_EASY_FIRESTORE_STATE");
					return true;
				});
		},
		addUserDetails({dispatch}){
			var user = {
				displayName: firebase.auth().currentUser.displayName,
				email: firebase.auth().currentUser.email
			}
			dispatch("patch", user )
		}
	},
};

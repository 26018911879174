<template>
	<mw-dialog v-model="dialog" submitBtn cancelBtn @submit="save">
		<template v-slot:button>
			<v-btn icon @click="dialog = true">
				<v-icon>mdi-cog</v-icon>
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<v-textarea
				label="Help Text"
				v-model="question.help_text"
			></v-textarea>
			<mw-select-or-create
				v-if="analyticsCategory"
				v-model="question.analytics_category"
				storeModule="categoryOptions"
				:include="analyticsCategory.options"
				@input="(v) => (question.analytics_category = v)"
				:multiple="false"
			>
			</mw-select-or-create>
			<v-text-field-simplemask
				label="Custom ID"
				v-bind:options="{ inputMask: '##-##-##', outputMask: '##-##-##', }"
				v-model="question.custom_id"
			>
			</v-text-field-simplemask>
				<v-checkbox
					label="Hide Polling"
					v-model="question.hide_polling"
				></v-checkbox>

		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";
export default {
	name: "EditQuestionSettings",
	props: {
		id: { type: String }, // the id of the question
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		analyticsCategory() {
			return this.$store.state.categories.data.analyticsCategory;
		},
	},
	components: {
		MwDialog,
		MwSelectOrCreate,
	},
	methods: {
		save() {
			let update = {
				id: this.id,
				analytics_category: this.question.analytics_category || null,
				custom_id: this.question.custom_id || null,
				help_text: this.question.help_text || null,
				hide_polling: this.question.hide_polling || null,
			};
			this.$store.dispatch("questions/patch", update);
		},
	},
};
</script>

<template>
	<v-sheet flat>
		<mw-edit :id="id" storeModule="users" :model="model">
			<template v-slot:email="slotProps">
				<v-text-field
					v-model="slotProps.data.email"
					:label="$t('misc.email')"
					@input="(v) => (slotProps.data['email'] = parseEmail(v))"
					:rules="[(v) => mwutils.validateEmail(v) || 'Invalid Email']"
				></v-text-field>
			</template>
			<template v-slot:organisations="slotProps">
				<mw-select-or-create
					v-model="slotProps.data.organisations"
					:label="$t('users.fields.organisation')"
					storeModule="organisations"
					:multiple="true"
				>
				</mw-select-or-create>
			</template>
			<template v-slot:append v-if="id">
				<edit-stakeholder-permissions
					:id="id"
				></edit-stakeholder-permissions>
			</template>
		</mw-edit>
		<v-btn color="green" dark link target="_blank" :to="`/support/${id}`">Provide Video Support</v-btn>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import User from "@/models/User";
import MwEdit from "@c/ui/MwEdit";
import EditStakeholderPermissions from "@c/edits/EditStakeholderPermissions";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";
export default {
	name: "EditUser",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return User;
		},
	},
	methods: {
		parseEmail(a) {
			return a.toLowerCase().trim();
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate,
		EditStakeholderPermissions,
	},
};
</script>

export default {
    title: {
        value: "title", 
        component: "v-text-field",
        default: "", 
        display: true, 
        label: "indicators.fields.title"
    },
    question_title: {
        value: "question_title", 
        component: "v-textarea",
        default: "", 
        display: true, 
        label: "indicators.fields.question_title"
    },
    staff_survey_title: {
        value: "staff_survey_title", 
        component: "v-textarea",
        default: "", 
        display: true, 
        label: "indicators.fields.staff_survey_title"
    },
    description: {
        value: "description", 
        default: "", 
        component: "v-textarea",
        display: true, 
        label: "misc.description"
    },
    tags: { 
        value: "tags", 
        default: [], 
        display: true, 
        label: "tags.nav.plural"
    }, 
}
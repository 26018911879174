export default {
    messages: {
        hello: "Awright", 
        saved: "Saved",
        oops: "Something's gone wrong here. Please contact help@m.works"
    }, 
    auth: {
        login: "Login",
        logged_in: "Logged In",
        logout: "Logout"
    },
    errors: {
        error: "Error", 
        not_found: "Record not found"
    },
    misc: {
        email: "Email",
        search: "Search",
        are_you_sure: "Are you sure?",
        description: "Description",
        name: "Name",
        color: "Colour", 
        position: "Position",
        paste: "Paste",
        multiple: "Multiple", 
        preview: "Previews"
    }, 
    buttons: {
        copy: "Copy",
        collapse: "Collapse Menu",
        cancel: "Cancel", 
        new: "New", 
        edit: "Edit",
        delete: "Delete",
        save: "Save", 
        submit: "Submit",
        import: "Import", 
        fields: "Fields",
        paste: "Paste"
    },
    config: {
        fields: {
            chat: "Include chat",
            timed_chat: "Show or hide chat by time of day",
            chat_start: "Start start time",
            chat_end: "Start end time",
            add_new_section: "Select a section or add a new one",
            polling: "Include polling", 
            delegation: "Include delegation", 
        }
    },
    indicators: {
        fields: {
            title: "Indicator title", 
            question_title: "Question wording for this indicator", 
            staff_survey_title: "Staff survey specific wording"
        }
    }, 
    categories: {
        fields: {
            fits: "This category can be applied to", 
        }
    },
    questions: {
        fields: {
            type: "Type",
            title: "Question Text",
            scored: "Question is scored",
            answer_options: "Answer Options", 
            conditions: "Conditions", 
            visibility: "Question Visibility", 
            exclude_from_count: "Exclude from the completion rate"
        }
    },
    organisations: {
        fields: {
            test_data: "Test Data, exclude from dashboard",
            users: "Users"
        }
    }, 
    sections: {
        fields: {
            theme: "Theme",
            introduction: "Introduction", 
            allow: "Allow the functions: "
        }
    },
    users: {
        fields: {
            organisation: "Assessment Organisations",
            displayName: "Name", 
            is_stakeholder: "User has stakeholder permissions",
            is_universal: "User can access all organisations", 
            stakeholder_organisations: "Stakeholder organisations"
        }
    }
}
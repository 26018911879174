<template>
	<div v-if="user">
		<div id="video-wrapper" class="video"></div>
		<div v-if="user.screenshare_support_available">
			<p>
				Support available to user
			</p>
			<v-btn color="red" dark @click="endSupport">End Support</v-btn>
		</div>
		<p v-else>
			No support for user
		</p>
	</div>
</template>
<style lang="less">
.video {
	max-width: 800px;
	video {
		width: 100%;
	}
}
</style>
<script>
import Twilio from "twilio-video";
import videoUtilities from "@/assets/videoUtilities";

export default {
	name: "VideoSupport",
	props: {
		id: { type: String },
	},
	data: () => ({
		token: null,
		room: null,
	}),
	computed: {
		identity() {
			return this.$store.state.auth.data.id;
		},
		user() {
			return this.$store.state.users.data[this.id];
		},
		roomName() {
			return `${this.user.id}-support`;
		},
	},
	methods: {
		async retrieveToken() {
			const self = this;
			return videoUtilities
				.getToken(this.roomName, this.identity)
				.then((res) => {
					self.token = res;
					return;
				});
		},
		startSupport() {
			this.$store.dispatch("users/patch", {
				id: this.id,
				screenshare_support_available: true,
			});
			this.startVideo();
		},
		endSupport() {
			this.$store.dispatch("users/patch", {
				id: this.id,
				screenshare_support_available: false,
			});
			this.stopVideo();
		},
		startVideo() {
			const self = this;
			self.retrieveToken().then(() => {
				Twilio.connect(self.token, {
					name: self.roomName,
					audio: false,
					video: false,
				}).then((room) => {
					const container = document.getElementById("video-wrapper");
					self.room = room;

					room.participants.forEach((participant) => {
						videoUtilities.handleParticipantConnected(
							participant,
							container
						);
					});
					room.on("participantConnected", (participant) => {
						videoUtilities.handleParticipantConnected(
							participant,
							container
						);
					});

					room.on("trackRemoved", (track) => {
						videoUtilities.detachTracks([track]);
					});
					room.on("trackStopped", (track) => {
						videoUtilities.detachTracks([track]);
					});

					room.on("participantDisconnected", function(participant) {
						videoUtilities.handleParticipantDisonnected(participant);
					});
				});
			});
		},
		stopVideo(v) {
			if (!v) {
				this.room.disconnect();
			}
		},
	},
	created() {
		this.$store.dispatch("users/fetchById", this.id).then(() => {
			this.startSupport();
		});
	},
	destroyed() {
		this.endSupport();
	},
};
</script>

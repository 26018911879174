<template>
	<v-sheet>
		<mw-list storeModule="supportLog" :columns="headers" path="support">
			<template v-slot:item.user="slotProps">
				<span v-if="users[slotProps.user]">{{
					users[slotProps.user].displayName
				}}</span>
			</template>
			<template v-slot:item.organisation="slotProps">
				<span v-if="users[slotProps.organisation]">{{
					organisations[slotProps.organisation].name
				}}</span>
			</template>
		</mw-list>
		<import
			storeModule="supportLog"
			defaultFields="organisation, user, status"
		></import>
	</v-sheet>
</template>

<script type="text/javascript">
import Import from "@c/buttons/Import";
import MwList from "@c/ui/MwList";
export default {
	name: "Support",
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		organisations() {
			return this.$store.state.organisations.data;
		},
		headers() {
			return [
				{ value: "user", text: "User", default: true },
				{ value: "organisation", text: "Organisation", default: true },
				{ value: "status", text: "Status", default: true },
			];
		},
	},
	created() {
		this.$store.dispatch("users/openDBChannel");
		this.$store.dispatch("organisations/openDBChannel");
	},
	destroyed() {
		this.$store.dispatch("users/closeDBChannel");
		this.$store.dispatch("organisations/closeDBChannel");
	},

	components: {
		MwList,
		Import,
	},
};
//
</script>
"

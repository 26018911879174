export default {
	firestorePath: "supportLog",
	firestoreRefType: "collection",
	moduleName: "supportLog",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-face-agent",
		name: {
			single: "support", 
			plural: "support"
		},
		path: "/support"
	},
	sync: {
		where: [
			["status", "in", ["Open", "Investigating", "Follow-up", "Escalated", "Resolved", "Closed Unresolved"]	]
		], 
		defaultValues: {
			status: "Open"
		},
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};

export default {
    name: {
        value: "name", 
        display: true, 
        label: "Name", 
    },
    title: {
        value: "title", 
        display: true, 
        label: "Title", 
        component: "v-text-field"
    },
    html: {
        value: "html", 
        display: true, 
        label: "emailTemplates.field.html"
    },
}
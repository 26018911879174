<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="supportLog"
			:model="model"
			path="suport"
			@saved="$emit('saved')"
		>
			<template v-slot:user="slotProps">
				<v-select v-model="slotProps.data.user" :items="users" label="User">
				</v-select>
			</template>
			<template v-slot:organisation="slotProps">
				<v-select
					v-model="slotProps.data.organisation"
					:items="organisations"
					label="Organisation"
				>
				</v-select>
			</template>

			<template v-slot:tags="slotProps">
				<mw-select-or-create
					v-model="slotProps.data.tags"
					storeModule="categoryOptions"
					label="Tags"
					:multiple="true"
				>
				</mw-select-or-create>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less">
.ql-editor {
	max-height: 600px;
	margin-bottom: 20px;
}
</style>

<script type="text/javascript">
import Support from "@/models/Support";
import MwEdit from "@c/ui/MwEdit";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";

export default {
	name: "EditSupport",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Support;
		},
		users() {
			let users = this.$store.state.users.data;
			return this.mwutils.itemsArray(users, "displayName");
		},
		organisations() {
			let organisations = this.$store.state.organisations.data;
			return this.mwutils.itemsArray(organisations);
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate,
	},
	created() {
		this.$store.dispatch("users/openDBChannel");
		this.$store.dispatch("organisations/openDBChannel");
	},
	destroyed() {
		this.$store.dispatch("users/closeDBChannel");
		this.$store.dispatch("organisations/closeDBChannel");
	},
};
//
</script>
"

export default {
    name: {
        value: "name", 
        component: "v-text-field",
        default: "", 
        display: true, 
        label: "misc.name"
    },
    fits: {
        value: "fits",
        component: "v-select",
        label: "categories.fields.fits",
        default: [],
        display: true,
        options: {
            multiple: true, 
        },
        items: ["indicators", "questions", "sections", "organisations", "users", "sectionResponses"]
    },
    options: {
        value: "options",
        default: [], 
        display: true
    },
    multiple: {
        value: "multiple", 
        display: true,  
        component: "v-checkbox", 
        label: "misc.multiple"
    }
}
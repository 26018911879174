<template>
	<span>	
		<edit-button v-bind="$props"></edit-button>
		<copy-button :id="id" :storeModule="storeModule"></copy-button>
		<delete-button :id="id" :storeModule="storeModule"></delete-button>
	</span>	
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";

	import DeleteButton from "@/components/buttons/DeleteButton";
	import CopyButton from "@/components/buttons/CopyButton";
	import EditButton from "@/components/buttons/EditButton";

	export default {
		name: "ListButtons", 
		props: {
			id: { type: String }, // @doc the ID of the object 
			storeModule: { type: String }, // the corresponding vuex module
			path: {  type: String  } // the path for the url - almost always the same as the storeModule
		},
		components: {
			DeleteButton, 
			CopyButton,
			EditButton
		},

	}
// </script>"
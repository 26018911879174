import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import firebase from "firebase";
import VueI18n from "vue-i18n";
import english from "@/assets/translations/english.js";
import IdleVue from "idle-vue";
import "./plugins/vuetify-mask.js";


import mwutils from "@/assets/utilities.js";

Vue.config.productionTip = false;

Vue.prototype.mwutils = mwutils;

Vue.use(VueMoment, {
	moment,
});

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: {
		en: english,
	},
});

var firebaseConfig = {
	apiKey: "AIzaSyBdW6UBnyaDRc6amjZkkGXOcURzfBM2-iE",
	authDomain: "dma-amb.firebaseapp.com",
	projectId: "dma-amb",
	storageBucket: "dma-amb.appspot.com",
	messagingSenderId: "71045371376",
	appId: "1:71045371376:web:39b0fec8c500207c91f935",
	measurementId: "G-EJ6B0PN403",
};

firebase.initializeApp(firebaseConfig);
firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store.dispatch("auth/start")
		.catch(() => {
			router.replace("/error")
		})
	}
});

Vue.use(IdleVue, {
	store,
	idleTime: 1200000,
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");

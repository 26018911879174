<template>
  <v-app>
    <top-nav></top-nav>
    <sidebar></sidebar>
    <v-main>
      <v-container class="pa-8">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vuex from "vuex";
import TopNav from "@/components/navigation/Top.vue"
import Sidebar from "@/components/navigation/Side.vue"
export default {
  name: 'App',
  data: () => ({
  }),
  computed: {
    ...Vuex.mapState( {
      state: state => state
    })
  },
  components: {
    TopNav, 
    Sidebar
  }
};
</script>

<template>
	<v-sheet flat>
		<mw-edit :id="id" storeModule="indicators" :model="model">
			<template v-slot:tags="slotProps">
				<mw-select-or-create
					v-model="slotProps.data.tags"
					storeModule="categoryOptions"
					:multiple="true"
				>
				</mw-select-or-create>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Indicator from "@//models/Indicator";
import MwEdit from "@c/ui/MwEdit";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";

export default {
	name: "EditIndictor",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Indicator;
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate,
	},
	created() {
		this.$store.dispatch("categories/fetchById", "tags");
	},
};
//
</script>
"

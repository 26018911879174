import modules from "./moduleLoader.js";

export default {
	clearAll({ commit }) {
		var exclude = [];
		Object.keys(modules)
			.filter((m) => !exclude.includes(m))
			.forEach((m) => {
				commit(`${m}/RESET_VUEX_EASY_FIRESTORE_STATE`);
			});
	},
	deleteDoc({ dispatch }, { id, storeModule }) {
		return dispatch(`${storeModule}/set`, { id: id, status: "deleted" }).then(
			() => {
				return true;
			}
		);
	},
	start() {
		// dispatch("indicators/fetchAndAdd");
	},
};

export default {     
	firestorePath: "users",
	firestoreRefType: "collection",
	moduleName: "users",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-account",
		name: {
			single: "User",  
			plural: "Users"
		},
		path: "/users"
	},
	sync: {
		where: [
			["status", "in", ["published", "replaced", "updated", "new", "pending"]]
		], 
		defaultValues: {
			status: "pending"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

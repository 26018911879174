export default {     
	firestorePath: "config/default",
	firestoreRefType: "doc",
	moduleName: "config",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		defaultValues: {
			sections: [], 
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

<template>
	<v-card flat>
		<v-card-text v-if="section">
			<v-simple-table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th width="30%">Content</th>
						<th width="30%">Indicator</th>
						<th width="30%">Question</th>
						<th>
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<edit-question
						@deleteItem="deleteItem(question)"
						@move="(direction) => move(question, direction)"
						v-for="question in section.questions"
						:key="question"
						:id="question"
					></edit-question>
				</tbody>
			</v-simple-table>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="addQuestion" color="primary">
				{{ $t("buttons.new") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less"></style>

<script type="text/javascript">
import EditQuestion from "@/components/edits/EditQuestion";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

export default {
	name: "EditQuestions",
	props: {
		id: { type: String }, // @doc the id of the section
	},
	computed: {
		section() {
			return this.$store.state.sections.data[this.id];
		},
		question_templates() {
			return this.$store.state.question_templates.data;
		},
		indicators() {
			return this.$store.state.indicators.data;
		},
	},
	components: {
		EditQuestion,
	},
	methods: {
		addQuestion() {
			var self = this;
			self.$store.dispatch("questions/insert", {}).then((res) => {
				self.$store.dispatch("sections/patch", {
					id: self.id,
					questions: arrayUnion(res),
				});
			});
		},
		deleteItem(id) {
			this.$store.dispatch("sections/patch", {
				id: this.id,
				questions: arrayRemove(id),
			});
		},
		move(id, direction) {
			var values = [...this.section.questions];
			this.mwutils.moveItemInArray(id, values, direction);
			this.$store.dispatch("sections/patch", {
				id: this.id,
				questions: values,
			});
		},
	},
	created() {
		this.$store.dispatch("indicators/openDBChannel");
		this.$store.dispatch("questionTemplates/openDBChannel");
		this.$store.dispatch("categories/fetchById", "service");
		this.$store.dispatch("categories/fetchById", "jobFunction");
	},
	destroyed() {
		this.$store.dispatch("indicators/closeDBChannel");
		this.$store.dispatch("questionTemplates/closeDBChannel");
	},
};
</script>

export default {  
    name: {
        value: "name", 
        display: true, 
        default: "default", 
        component: "v-text-field"
    }, 
    staff_survey: {
        value: "staff_survey", 
        display: true, 
    }, 
    chat: {
        value: "chat", 
        label:"config.fields.chat", 
        default: {
            show: true, 
            timed: false, 
            id: "",
            start: "09:00",
            end: "18:00", 
            color: "#ccc", 
            title_closed: 'Live chat',
			title_open: 'Live chat',
			intro_message: 'Hello. How can I help you?',
			auto_response: 'Thank you for your message. An operator will respond shortly',
			auto_noresponse: 'Sorry it\'s taking us longer than usual to respond. ',
            floating_button: true
        }, 
        display: true,
    },
    polling: {
        value: "polling", 
        label:"config.fields.polling", 
        default: true, 
        display: true,
        component: "v-select", 
        items: ["none", "question", "section"]
    },    
    delegation: {
        value: "delegation", 
        label:"config.fields.delegation", 
        default: true, 
        display: true,
        component: "v-select", 
        items: ["none", "email", "link"]
    },    
    sections: {
        value: "sections", 
        label:"config.fields.sections", 
        default: [], 
        display: true,
    }
}

import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/error",
		name: "Error",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Error.vue"),
	},
	{
		path: "/config",
		name: "Config",
		meta: {
			requires_auth: true,
			sidebar: true,
		},
		component: () => import(`@/components/edits/EditConfig.vue`),
	},
	{
		path: "/support/:id",
		name: "VideoSupport", 
		props: true,
		meta: {
			requires_auth: true,
			sidebar: true,
		},
		component: () => import(`@/components/support/VideoSupport.vue`),
	}
];

import modules from "@/modules.js";

// const adminPages = [
// 	{ single: "Indicator", plural: "indicators", list: "Indicators", edit: "EditIndicator" },
// 	{ single: "Category", plural: "categories", list: "Categories", edit: "EditCategory" },
// 	{ single: "CategoryOption", plural: "categoryOptions", list: "CategoryOptions", edit: "EditCategoryOption" },
// ];

Object.values(modules).forEach((page) => {
	routes.push({
		path: `/${page.path}`,
		name: page.plural,
		meta: {
			requires_auth: true,
		},
		component: () => import(`@/components/lists/${page.plural}.vue`),
	});
	routes.push({
		path: `/${page.path}/new`,
		name: `New${page.single}`,
		meta: {
			requires_auth: true,
			sidebar: true,
		},
		component: () => import(`@/components/edits/Edit${page.single}.vue`),
	});
	routes.push({
		path: `/${page.path}/:id`,
		name: `Edit${page.single}`,
		props: true,
		meta: {
			requires_auth: true,
			sidebar: true,
		},
		component: () => import(`@/components/edits/Edit${page.single}.vue`),
	});
});

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;

<template>
    
    <v-dialog width="360">
        <template v-slot:activator="{on}">
            <v-btn icon small v-on="on">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "EditConfigRules"

}
</script>
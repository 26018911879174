import utilities from "@/assets/utilities";

export default {
	firestorePath: "content",
	firestoreRefType: "collection",
	moduleName: "content",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-file-edit",
		name: {
			single: "Content", 
			plural: "Content"
		},
		path: "/content"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		},
		insertBatchHook: function (updateStore, docs) { 
			for( var i = 0; i < docs.length; i++ ){
				docs[i] = utilities.nameToId(docs[i]);
				docs[i].status = "published";
			}
			return updateStore(docs) 
		},
		insertHookBeforeSync: (updateFirestore, doc) => { 
			doc = utilities.nameToId(doc);
			return updateFirestore(doc);
		},
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};

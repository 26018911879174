import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    themes: {
        light: {
            primary: "#1478C6"
        }
    }
});

<template>
	<v-sheet>
		<mw-list storeModule="content" :columns="headers">
			<template v-slot:item.html="slotProps">
				<span v-if="slotProps.html" v-html="`${slotProps.html.substr(0,100)}...`"></span>
			</template>
		</mw-list>
		<import storeModule="content" defaultFields="name,title,html"></import>
	</v-sheet>
</template>


<script type="text/javascript">
import Import from "@c/buttons/Import";
import MwList from "@c/ui/MwList";
export default {
	name: "Content",
	data: () => {
		return {
			filtered: false,
		};
	},
	computed: {
		headers() {
			return [
				{ value: "id", text: "ID", default: true },
				{ value: "title", text: "Title", default: true },
				{ value: "html", text: "Content", default: true },
			];
		},
	},
	components: {
		MwList,
		Import,
	},
};
//
</script>
"

export default {
    identifier: {
        value: "identifier", 
        display: true,
        default: ""
    }, 
    value: {
        value: "value", 
        display: true, 
        default: ""
    },
    operator: {
        value: "operator", 
        display: true, 
        items: ["in", "==", "!="],
        default: "=="
    },  

}
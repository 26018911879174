<template>
	<mw-dialog
		v-model="dialog"
		:title="$t('buttons.import')"
		cancelBtn
		large
		submitBtn
		@submit="submit"
	>
		<template v-slot:button>
			<v-btn @click="dialog = true" color="primary">{{ $t("buttons.import") }}</v-btn>
		</template>
		<template v-slot:dialog-body>

				<v-tabs v-model="tab">
					<v-tab>{{ $t("buttons.import") }}</v-tab>
					<v-tab>{{ $t("misc.preview") }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-textarea
							v-model="paste"
							:label="$t('misc.paste')"
						></v-textarea>
						<v-text-field
							v-model="fieldString"
							:label="$t('misc.fields')"
						></v-text-field>
					</v-tab-item>
					<v-tab-item>
						<v-data-table
							:items="items"
							:headers="headers"
						></v-data-table>
					</v-tab-item>
				</v-tabs-items>
		</template>
	</mw-dialog>
</template>

<style lang="less"></style>

<script type="text/javascript">
import MwDialog from "@c/ui/MwDialog.vue";
export default {
	name: "Import",
	props: {
		storeModule: {type: String },
		defaultFields: { type: String, default: "" },
	},
	data: () => {
		return {
			tab: 0,
			dialog: false,
			fieldString: "",
			paste: "",
		};
	},
	components: { MwDialog },
	computed: {
		fields() {
			return this.fieldString.split(",")
		},
		headers() {
			var fields = this.fields;
			fields = fields.map((f) => {
				return { text: f, value: f.trim() };
			});
			return fields;
		},
		items() {
			var self = this;
			var text = self.paste;
			if (!text.length) {
				return;
			}
			var lines = text.split("\n");
			var items = [];
			lines.forEach((line) => {
				var row = {};
				var cells = line.split("\t");
				self.headers.forEach((h, i) => {
					var value = cells[i];
					row[h.value] = value;
				});
				items.push(row);
			});
			return items;
		},
	},
	methods: {
		submit() {
			this.$store.dispatch(`${this.storeModule}/insertBatch`, this.items ).then( () => {
				this.dialog = false;
			})
		},
	},
	created() {
		if (this.defaultFields) {
			this.fieldString = this.defaultFields;
		}
	},
};
//
</script>
"

export default {
	firestorePath: "indicators",
	firestoreRefType: "collection",
	moduleName: "indicators",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-bullseye-arrow",
		name: {
			single: "Indicator", 
			plural: "Indicators"
		},
		path: "/indicators"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};

export default {
	name: {
		value: "name",
		component: "v-text-field",
		default: "",
		display: true,
		label: "misc.name",
	},
	introduction: {
		value: "introduction",
		component: "v-textarea",
		default: "",
		display: true,
		label: "sections.fields.introduction",
	},
	introductions: {
		value: "introductions",
		default: {},
		display: true,
	},
	allow: {
		value: "allow",
		component: "v-select",
		default: ["delegation", "polling", "complete"],
		display: true,
		items: ["delegation", "polling", "complete"],
		options: {
			multiple: true,
			chips: true,
            "small-chips": true
		},
		label: "sections.fields.allow",
	},
	theme: {
		value: "theme",
		default: "",
		display: true,
		label: "sections.fields.theme",
	},
	questions: {
		value: "questions",
		default: "",
		display: true,
		label: "sections.fields.theme",
	},
};

<template>
	<span>
		<v-item-group v-model="selected" :multiple="multiple" v-if="options.length">
			<v-item
				v-slot="{ active, toggle }"
				v-for="(option, i) in options"
				:key="i"
				:value="option.id"
			>
				<v-chip
					small
					class="mr-2"
					@click="toggle"
					:color="active ? 'green' : 'grey'"
					>{{ option.name }}</v-chip
				>
			</v-item>
		</v-item-group>
	</span>
</template>

<script>
export default {
	name: "EditCategoryRule",
	props: {
		value: {},
		category: { type: String },
		operator: { type: String, default: "==" },
	},
	data: () => {
		return {
			selected: [],
		};
	},
	computed: {
		multiple() {
			if (this.operator == "in") {
				return true;
			}
			return false;
		},
		options() {
			const self = this;
			if (!this.category) {
				return;
			}
			var category = this.$store.state.categories.data[this.category];
			if (!category) {
				return [];
			}
			var options = category.options
				.map((a) => {
					return self.$store.state.categoryOptions.data[a];
				})
				.filter((a) => a);
			return options;
		},
	},
	watch: {
		multiple: {
			immediate: true,
			handler() {
				if (this.multiple) {
					this.selected == [];
				} else {
					this.selected = "";
				}
			},
		},
		selected(value) {
			if (!value) {
				return;
			}
			let rule = {
				category: this.category,
				operator: this.multiple ? "in" : "==",
				value: this.selected,
			};
			this.$emit("input", rule);
		},
	},
	created() {
		if( !this.value ){
			return;
		}
		this.selected = this.value;
		// if (this.value[0]) {
		// 	this.selected = this.value;
		// }
		// if (this.value.value) {
		// 	this.selected = this.value.value;
		// }
	},
};
</script>

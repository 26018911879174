<template>
    
    <div>
        <vue-editor v-model="questionTemplate.html"></vue-editor>
        <p class="text-right mt-3">
            <v-btn @click="save" color="primary">{{$t("buttons.save")}}</v-btn>
        </p>

    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
    name: "Html", 
    components: {
        VueEditor
    },
    props: {
        id: { type: String } // the id of hte question template
    }, 
    computed:{ 
        questionTemplate(){
            return this.$store.state.questionTemplates.data[this.id] || {}
        }
    },
    methods: {
        save(){
            this.$store.dispatch("questionTemplates/patch", {id: this.id, html: this.questionTemplate.html})
        }
    }

}
</script>

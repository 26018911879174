<template>
	<v-app-bar app color="transparent" flat>
		<!-- <v-toolbar-title>EMAS DMA</v-toolbar-title> -->
		<v-spacer></v-spacer>
		<img
			@click="goHome"
			align-with-title
			class="mt-4"
			height="80"
			src="../../assets/images/aace-logo.jpg"
		/>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
export default {
	name: "TopNav",
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
};
</script>

<template>
	<mw-dialog
		v-model="dialog"
		large
		submitBtn
		cancelBtn
		@submit="save"
		:title="$t('questions.fields.visibility')"
	>
		<template v-slot:button>
			<v-btn icon @click="dialog = true">
				<v-icon>mdi-eye-circle-outline</v-icon>
				<v-badge
					v-if="conditionCount"
					:content="conditionCount"
					color="green"
				></v-badge>
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<v-simple-table width="100%" class="ma-3">
				<thead>
					<tr>
						<th width="4%"></th>
						<th> &nbsp;</th>
						<th> &nbsp;</th>
						<th> &nbsp;</th>
						<th> &nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(rule, i) in rules">
						<visibility-condition
							v-if="!rule.category"
							:key="i"
							:rule="rule"
							@input="(v) => changeRule(v, i)"
						>
							<template v-slot:prepend>
								<td>
									<v-btn icon @click="deleteRule(i)"
										><v-icon>mdi-delete</v-icon></v-btn
									>
								</td>
							</template>
						</visibility-condition>
						<template v-else>
							<tr :key="i">
								<td>
									<v-btn icon @click="deleteRule(i)"
										><v-icon>mdi-delete</v-icon></v-btn
									>
								</td>
								<td colspan="4">
									<edit-category-rule
										v-bind="rule"
										@input="(v) => changeRule(v, i)"
										class="mb-3"
									>
									</edit-category-rule>
								</td>
							</tr>
						</template>
					</template>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="3">
							<v-select
								class="ma-4"
								placeholder="New rule"
								v-model="newType"
								:items="types"
								clearable
							>
							</v-select>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</template>
	</mw-dialog>
</template>

<script>
//TODO - Becca this is garbage.

// Rules should be available for any applicable category
// You should be able to specify what object the category is for i.e jobFunction->user service->org

import MwDialog from "@c/ui/MwDialog";
import EditCategoryRule from "@/components/edits/EditCategoryRule.vue";
import VisibilityCondition from "./VisibilityCondition.vue";
import Rule from "@/models/Rule";
import CategoryRule from "@/models/CategoryRule";

export default {
	name: "EditQuestionVisibility",
	props: {
		id: {},
	},
	data: () => {
		return {
			dialog: false,
			rules: [],
			types: ["question", "service", "jobFunction"],
			newType: "",
		};
	},
	components: {
		MwDialog,
		VisibilityCondition,
		EditCategoryRule,
	},
	computed: {
		services() {
			return this.$store.getters["categoryOptions/services"];
		},
		question() {
			return this.$store.state.questions.data[this.id];
		},
		conditionCount() {
			return this.rules.length;
		},
	},
	watch: {
		question: {
			immediate: true, 
			handler(){

				if (this.question.rules) {
					this.rules = this.question.rules;
				}
			}
		},
		newType(value) {
			if (value) {
				this.addCondition(value);
			}
		},
	},
	methods: {
		deleteRule(i) {
			this.rules.splice(i, 1);
		},
		addCondition(model) {
			let rule;
			if (model == "question") {
				rule = this.mwutils.getEmpty(Rule);
				rule.identifier = `${this.$route.params.id}.`;
			} else {
				rule = this.mwutils.getEmpty(CategoryRule);
				rule.category = model;
				rule.operator = "in";
			}
			this.rules.push(rule);
			this.newType = null;
		},
		save() {
			let update = {
				id: this.id,
				rules: this.rules,
			};
			this.$store.dispatch("questions/patch", update);
		},
		changeRule(v, i) {
			this.rules[i] = v;
		},
	},
};
</script>

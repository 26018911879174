import mwutils from "../../assets/utilities";
export default {
	firestorePath: "categoryOptions",
	firestoreRefType: "collection",
	moduleName: "categoryOptions",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
		insertHookBeforeSync: (updateFirestore, doc) => {
			doc.nicename = mwutils.strToKey(doc.name);
			return updateFirestore(doc);
		},
	},
	getters: {
		themes(state, _, __, rootGetters ) {
			let options = rootGetters["categories/getOptions"]("theme");
			return options.map( (opt) => state.data[opt] )
		},
		analyticsCategories(state, _, __, rootGetters ) {
			let options = rootGetters["categories/getOptions"]("analyticsCategory");
			return options.map( (opt) => state.data[opt] )
		},
		services(state, _, __, rootGetters) {
			let options = rootGetters["categories/getOptions"]("service");
			return options.map( (opt) => state.data[opt] )
		},
	},
	mutations: {},
	actions: {},
};

<template>
	<v-sheet flat>
		<mw-edit :id="id" storeModule="config" :model="model" mode="doc">
			<template v-slot:staff_survey="slotProps">
				<v-select :items="sectionItems" :value="slotProps.data.staff_survey" label="Staff Survey"></v-select>
			</template>
			<template v-slot:sections="slotProps">
				<mw-select-and-order
					v-model="slotProps.data.sections"
					storeModule="sections"
				>
					<template v-slot:appendHeaders>
						<th>SplitBY</th>
						<th>LimitTo</th>
					</template>
					<template v-slot:append="{ id }">
						<td>
							<edit-category-multiple
								category="service"
								@input="(v) => update( 'multiple', id, v)"
								v-model="config.multiple[id]"
							></edit-category-multiple>
						</td>
						<td>
							<edit-category-rule 
								category="service"
								operator="=="
								@input="(v) => update( 'rules', id, [v])"
								v-model="config.rules[id]"
							></edit-category-rule>
						</td>
					</template>
				</mw-select-and-order>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Config from "@/models/Config";
import MwEdit from "@c/ui/MwEdit";
import MwSelectAndOrder from "@c/ui/MwSelectAndOrder.vue";
import EditCategoryMultiple from "@/components/edits/EditCategoryMultiple.vue";
import EditCategoryRule from "@/components/edits/EditCategoryRule.vue";
export default {
	name: "EditConfig",
	computed: {
		model() {
			return Config;
		},
		config() {
			return this.$store.state.config.data;
		},
		sections(){
			return this.$store.state.sections.data;
		},
		sectionItems(){
			return this.mwutils.itemsArray(this.sections)
		}
	},
	data: () => {
		return {
			id: "default",
		};
	},
	components: {
		MwSelectAndOrder,
		MwEdit,
		EditCategoryMultiple,
		EditCategoryRule
	},
	methods: {
		update(key, id, value){
			if( value ){	
				this.$store.dispatch("config/set", {[key]: {[id]: value }})
			}
		}
	},
	created() {
		this.$store.dispatch("categories/fetchById", "service");
		this.$store.dispatch("categories/fetchById", "theme");
		this.$store.dispatch("sections/openDBChannel");
	},
	destroyed(){
		this.$store.dispatch("sections/closeDBChannel");
	}
};
</script>

export default {
	getFields(model) {
		return Object.values(model).filter( field => field.display )
	},
	getEmpty(model){
		var obj = {}
		Object.values(model).forEach( field => obj[field.value] = field.default )
		return obj;
	},
	prettyPrint: function(str, strCase ){
		if( !str ){ return "" }
		str = str.split("_").join(" ");
		str = str.split("-").join(" ");
		switch (strCase){
			case "TITLE":
				var sentence = str.toLowerCase().split(" ");
				for(var i = 0; i< sentence.length; i++){
					sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
				}
				return sentence.join(" ");
			case "SENTENCE":
				str = str.toLowerCase();
				str = str[0].toUpperCase() + str.slice(1);
				return str;
			case "LOWER":
				return str.toLowerCase();
			case "UPPER":
				return str.toUpperCase();
			default:
				return str;
		}
	},
	validateEmail(str){
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str)){
			return (true)
		}
		return false
	},
	nameToId(doc){
		doc.id = this.strToKey( doc.name ).split("-").join("");
		doc.id = this.lowerCaseFirst(doc.id)
		delete doc.name;
		return doc;
	},
	strToKey: function(str){
		if( !str ){ return "" }
		str = str.split(" ").join("-");
		str = str.replace(/[^\w\s]/gi, '-');
		return str.toLowerCase();
	},
	sortByKey: function(array, key) {
		return array.sort(function(a, b) {
			var x = a[key]; var y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	},
	moveItemInArray(id, array, direction){
		let delta = direction == "up" ? -1 : 1;
		var index = array.indexOf(id);
		var newIndex = index + delta;
		if (newIndex < 0  || newIndex == array.length) return;
		var indexes = [index, newIndex].sort((a, b) => a - b); 
		array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); 		
	},
	getPositions(array, property = "position" ){
		var keys = Object.keys(array);
		keys = keys.sort( ( a, b ) => {
			var x = array[a][property]; 
			var y = array[b][property]; 
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		})
		return keys;				

	},
	itemsArray(obj, text = "name" ){
		return Object.keys(obj).map( id => {
			return {
				value: id,
				text: obj[id][text] 
			}
		})
	},
	upperCaseFirst(str){
		return str.charAt(0).toUpperCase() + str.substr(1);
	},	
	lowerCaseFirst(str){
		return str.charAt(0).toLowerCase() + str.substr(1);
	}

}
<template>
	<v-dialog v-model="dialog" width="560">
		<template v-slot:activator="{ on }">
			<div class="button" v-on="on">
				<span class="pr-2 pl-2" v-if="indicator">{{ indicator ? indicator.title : "" }}</span>
				<v-icon v-else>mdi-plus-circle</v-icon>
			</div>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-autocomplete :items="items" v-model="value">
					<template v-slot:append v-if="value">
						<v-btn icon	router-link target="_blank" :to="`/indicators/${value}`">
							<v-icon>mdi-open-in-new</v-icon>
						</v-btn>
					</template> 
				</v-autocomplete>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false">{{
					$t("buttons.cancel")
				}}</v-btn>
				<v-btn color="primary" @click="submit">{{
					$t("buttons.submit")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<style lang="less">
div.button {
	cursor: pointer;
}
</style>

<script type="text/javascript">
export default {
	name: "SelectIndicator",
	props: {
		id: { type: String }, // @doc the id of the question
	},
	data: () => {
		return {
			dialog: false,
			value: "",
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		indicators() {
			return this.$store.state.indicators.data;
		},
		indicator() {
			if (!this.question) {
				return false;
			}
			return this.indicators[this.question.indicator];
		},
		items() {
			return this.mwutils.itemsArray(this.indicators, "title");
		},		

	},
	watch: {
		indicator: {
			immediate: true,
			handler(indicator) {
				if( indicator ){

					this.value = indicator.id;
				}
			},
		},
	},
	methods: {
		submit() {
            var self = this;
            self.$store.dispatch("questions/patch", {id: self.id, indicator: self.value}).then( () => {
                self.dialog = false;
            })
        },
	},
};
</script>

export default {
    questions: {
        single: "Question", 
        plural: "Questions",
        path: "questions"
    },
    questionTemplates: {
        single: "QuestionTemplate", 
        plural: "QuestionTemplates",
        path: "questionTemplates"
    },
    categories: {
        single: "Category", 
        plural: "Categories",
        path: "categories"
    },
    content: {
        single: "Content", 
        plural: "Content",
        path: "content"
    },
    categoryOptions: {
        single: "CategoryOption", 
        plural: "CategoryOptions",
        path: "categoryOptions"
    },
    sections: {
        single: "Section", 
        plural: "Sections",
        path: "sections"
    },
    indicators: {
        single: "Indicator", 
        plural: "Indicators",
        path: "indicators"
    },
    users: {
        single: "User", 
        plural: "Users",
        path: "users"
    },
    organisations: {
        single: "Organisation", 
        plural: "Organisations",
        path: "organisations"
    },
    emailTemplates: {
        single: "EmailTemplate",
        plural: "EmailTemplates",
        path: "emailTemplates"
    },
    support: {
        single: "Support",
        plural: "Support",
        path: "support"
    },
    knowledge: {
        single: "Knowledge",
        plural: "Knowledge",
        path: "knowledge"
    }
}
export default {
    name: {
        value: "name", 
        component: "v-text-field",
        default: "", 
        display: true, 
        label: "Internal Name"
    },
    title: {
        value: "title", 
        component: "v-text-field",
        default: "", 
        display: true, 
        label: "questions.fields.title"
    },
    description: {
        value: "description", 
        component: "v-textarea",
        default: "", 
        display: true, 
        label: "misc.description"
    },
    type: {
        value: "type", 
        component: "v-select",
        default: "", 
        items: ["tiles", "checkboxes", "comment", "html"],
        display: true, 
        label: "questions.fields.type"
    },
    answer_options: {
        value: "answer_options", 
        default: {}, 
        display: true, 
        label: "questions.fields.answer_options"
    },   
    scored: {
        value: "scored", 
        default: true, 
        display: true,
        component: "v-checkbox", 
        label: "questions.fields.scored"
    },   
    exclude_from_count: {
        value: "exclude_from_count", 
        default: false, 
        display: true,
        component: "v-checkbox", 
        label: "questions.fields.exclude_from_count"
    },   
    conditions:  {
        value: "conditions", 
        default: "", 
        display: true,
        label: "questions.fields.conditions"
    } 
}
import firebase from "firebase";
export default {
	getToken: async function(roomName, identity) {
		const functions = firebase.app().functions("europe-west1");
		const getToken = functions.httpsCallable("getVideoToken");
		const credentials = {
			roomName,
			identity,
		};
		const result = await getToken(credentials);
		return result.data.token;
	},
	attachTrack(track, container) {
		container.appendChild(track.attach());
	},
	detachTracks: function(tracks) {
		tracks.forEach(function(track) {
			track.detach().forEach(function(detachedElement) {
				detachedElement.remove();
			});
		});
	},
	handleParticipantConnected(participant, container) {
		participant.tracks.forEach((track) => {
			this.attachTrack(track, container);
		});

		participant.on("trackSubscribed", (track) => {
			this.attachTrack(track, container);
		});
	},
	handleParticipantDisconnected(participant) {
		let tracks = Array.from(participant.tracks.values());
		this.detachTracks(tracks);
	},
};

<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="content"
			:model="model"
			@saved="$emit('saved')"
		>
			<template v-slot:name="{ data }" v-if="!id">
				<v-text-field v-model="data.name"></v-text-field>
			</template>

			<template v-slot:html="{ data }">
				<mw-editor v-model="data.html"></mw-editor>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less">
.ql-editor {
	max-height: 600px;
	margin-bottom: 20px;
}
</style>

<script type="text/javascript">
import Content from "@/models/Content";
import MwEdit from "@c/ui/MwEdit";
import MwEditor from "@c/ui/MwEditor";

export default {
	name: "EditContent",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Content;
		},
	},
	components: {
		MwEdit,
		MwEditor
	},
};
//
</script>
"

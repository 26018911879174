export default {     
	firestorePath: "assessments",
	firestoreRefType: "collection",
	moduleName: "assessments",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			responses: {},
			organisation: false, 
			status: "published",
			config: "default",
			users: {}
		}
	},
	serverChange: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

export default {
	firestorePath: "emailTemplates",
	firestoreRefType: "collection",
	moduleName: "emailTemplates",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-email-edit",
		name: {
			single: "Email Template", 
			plural: "Email Templates"
		},
		path: "/emailTemplates"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		},
		
		// insertHookBeforeSync: (updateFirestore, doc) => { 
			// doc.id = mwutils.strToKey( doc.name ).split("-").join("");
			// delete doc.name;
			// return updateFirestore(doc);
		// },
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};

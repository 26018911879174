<template>
	<v-sheet flat>
		<v-tabs v-model="tab" class="mb-5">
			<v-tab>Info</v-tab>
			<v-tab v-if="id">Settings</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<mw-edit :id="id" storeModule="questionTemplates" :model="model">
				</mw-edit>
			</v-tab-item>
			<v-tab-item v-if="id">
				<v-component :is="component" :id="id"></v-component>
			</v-tab-item>
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import QuestionTemplate from "@/models/QuestionTemplate";
import MwEdit from "@c/ui/MwEdit";

export default {
	name: "EditQuestionTemplate",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
			tab: 0,
		};
	},
	computed: {
		model() {
			return QuestionTemplate;
		},
		question() {
			if (!this.id) {
				return;
			}
			return this.$store.state.questionTemplates.data[this.id];
		},
		component() {
			if (!this.question) {
				return;
			}
			var types = {
				checkboxes: "Checkboxes",
				html: "Html",
				tiles: "Tiles",
				comment: "Comment",
			};
			return types[this.question.type] || false;
		},
	},
	components: {
		MwEdit,
		Checkboxes: () =>
			import("@/components/survey/builder/questionTypes/Checkboxes"),
		Tiles: () => import("@/components/survey/builder/questionTypes/Tiles"),
		Html: () => import("@/components/survey/builder/questionTypes/Html"),
		Comment: () =>
			import("@/components/survey/builder/questionTypes/Comment"),
	},
};
</script>

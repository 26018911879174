<template>
	<tr v-if="question" class="align-top">
		<td>
			<v-btn small icon @click="$emit('deleteItem')"
				><v-icon>mdi-close-circle</v-icon></v-btn
			>
			<v-btn small icon @click="$emit('move', 'up')"
				><v-icon>mdi-arrow-up-circle</v-icon></v-btn
			>
			<v-btn small icon @click="$emit('move', 'down')"
				><v-icon>mdi-arrow-down-circle</v-icon></v-btn
			>
		</td>
		<td class="pa-5">
			<v-textarea
				dense
				rows="2"
				v-if="!question.hide_content"
				label="Optional question content"
				v-model="question.content"
				@input="update(question.content, 'content')"
			></v-textarea>
			<small>
				<v-checkbox
					label="Hide"
					v-model="question.hide_content"
					@click="update(question.hide_content, 'hide_content')"
				></v-checkbox>
			</small>
		</td>
		<td>
			<select-indicator :id="id"></select-indicator>
		</td>
		<td>
			<select-question-template :id="id"></select-question-template>
		</td>
		<td>
			<edit-question-visibility :id="id"></edit-question-visibility>
			<edit-question-settings :id="id"></edit-question-settings>
		</td>
	</tr>
</template>

<style lang="less">
	.align-top {
		td  {
			vertical-align: top;
			padding-top: 20px !important;
		}
	}

</style>

<script type="text/javascript">
import SelectIndicator from "@/components/survey/builder/SelectIndicator";
import SelectQuestionTemplate from "@/components/survey/builder/SelectQuestionTemplate.vue";
import EditQuestionVisibility from "@/components/survey/builder/EditQuestionVisibility.vue";
import EditQuestionSettings from "@/components/survey/builder/EditQuestionSettings.vue";
// import MwFormField from "@c/ui/MwFormField.vue";
import Question from "@/models/Question";

export default {
	name: "EditQuestion",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		SelectIndicator,
		SelectQuestionTemplate,
		EditQuestionVisibility,
		EditQuestionSettings,
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		model() {
			return Question;
		},
		indicator() {
			if (!this.question) {
				return false;
			}
			return this.$store.state.indicators.data[this.question.indicator];
		},
		indicatorIcon() {
			return this.$store.state.indicators.icon;
		},
	},
	methods: {
		update(value, key) {
			this.$store.dispatch("questions/patch", { id: this.id, [key]: value });
		},
	},
	created() {
		this.$store.dispatch("questions/fetchById", this.id);
		this.$store.dispatch("categories/fetchById", "analyticsCategory");
	},
};
</script>

export default {
    question: {
        value: "question", 
        display: true, 
        label: "Question",
        component: "v-textarea",
        default: "" 
    },
    answer: {
        value: "answer", 
        display: true, 
        label: "Answer",
        component: "v-textarea",
        default: "" 
    },
    tags: {
        value: "tags", 
        display: true, 
        label: "Tags",
        default: []
    },

}


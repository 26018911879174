<template>
	<v-list-item class="px-2"  router-link to="/profile">
		<v-list-item-avatar color="primary">
			<span v-if="initials" class="white--text">
				{{initials}}
			</span>
			<v-icon v-else>mdi-account-circle</v-icon>
		</v-list-item-avatar>

		<v-list-item-title>{{auth.displayName || $t("auth.logged_in")}}</v-list-item-title>

	</v-list-item>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	export default {
		name: "LoggedIn", 
		computed: {
			...Vuex.mapState({
				auth: state => state.auth.data
			}),
			initials(){
				var name = this.auth.displayName;
				if( !name ){
					return false
				}
				return name.split(" ").map( a => a.charAt(0) ).join("")
			}
		},
	}
</script>"

<template>
    
    <div>
        <answer-options v-model="questionTemplate.answer_options"></answer-options>
        <p class="text-right">
            <v-btn @click="save" color="primary">{{$t("buttons.save")}}</v-btn>
        </p>
    </div>
</template>

<script>
import AnswerOptions from "@/components/survey/builder/questionTypes/AnswerOptions.vue"
export default {
    name: "Tiles", 
    components: {
        AnswerOptions
    }, 
    props: {
        id: { type: String } // the id of hte question template
    }, 
    computed:{ 
        questionTemplate(){
            return this.$store.state.questionTemplates.data[this.id] || {}
        }
    }, 
    methods: {
        save(){
            const self = this;
            self.$store.dispatch("questionTemplates/patch", {id: self.id, answer_options: self.questionTemplate.answer_options} )
        }
    }

}
</script>

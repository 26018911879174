<template>
	<v-sheet flat>
		<v-tabs v-model="tab">
			<v-tab>
				Organisation
			</v-tab>
			<v-tab v-for="a in assessments" :key="a.id">
				Assessment
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" class="pt-4">
			<v-tab-item>
				<mw-edit :id="id" storeModule="organisations" :model="model">
					<template v-slot:service="slotProps" v-if="id">
						<mw-select-or-create v-model="slotProps.data['service']" storeModule="categoryOptions" :multiple="true" :include="services" @input="checkChange"> </mw-select-or-create>
					</template>
					<!-- <template v-slot:append>
						<v-select label="Lead Respondent" v-if="id" :value="leadRespondent" :items="userItems" @change="(v) => changeLeadRespondent(v)"></v-select>
					</template> -->
				</mw-edit>
			</v-tab-item>
			<v-tab-item v-for="a in assessments" :key="a.id">
				<edit-assessment :id="a.id"></edit-assessment>
			</v-tab-item>
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import { arrayUnion } from "vuex-easy-firestore";
import Organisation from "@/models/Organisation";
import MwEdit from "@c/ui/MwEdit";
import MwSelectOrCreate from "@c/ui/MwSelectOrCreate";
import EditAssessment from '@c/edits/EditAssessment.vue';

export default {
	name: "EditOrganisation",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
		// leadRespondent: { type: String },
	},
	data: () => {
		return {
			tab: 0
		}
	},
	computed: {
		organisation() {
			return this.$store.state.organisations.data[this.id];
		},
		// users() {
		// 	return this.$store.state.users.data;
		// },
		// userItems() {
		// 	const self = this;
		// 	let users = Object.values(self.users).filter((user) => {
		// 		return user.organisations && user.organisations.includes(self.id);
		// 	});
		// 	return users.map((user) => {
		// 		return { value: user.id, text: user.displayName };
		// 	});
		// },
		model() {
			return Organisation;
		},
		services() {
			if (!this.$store.state.categories.data["service"]) {
				return [];
			}
			return this.$store.state.categories.data["service"].options;
		},
		assessments() {
			const self = this;
			return Object.values(self.$store.state.assessments.data).filter((a) => a.organisation == self.id);
		},
		assessment() {
			const self = this;
			return self.assessments[0]
		},
	},
	components: {
		MwEdit,
		MwSelectOrCreate, 
		EditAssessment
	},
	methods: {
		checkChange(values) {
			var self = this;
			var newValues = values.filter((v) => !self.services.includes(v));
			newValues.forEach((value) => {
				this.$store.dispatch("categories/patch", {
					id: "service",
					options: arrayUnion(value),
				});
			});
		},
		changeLeadRespondent(nv) {
			this.$store.dispatch("organisations/patch", { id: this.id, users: { [nv]: "leadRespondent" } });
			this.$store.dispatch("assessments/patch", { id: this.assessment.id, users: { [nv]: "leadRespondent" } });
			if (this.leadRespondent) {
				let ov = this.leadRespondent;
				this.$store.dispatch("organisations/patch", { id: this.id, users: { [ov]: "user" } });
				this.$store.dispatch("assessments/patch", { id: this.assessment.id, users: { [ov]: "user" } });
			}
		},
	},
	watch: {
		organisation: {
			immediate: true,
			handler(organisation) {
				if (organisation) {
					const self = this;
					let leadRespondent = Object.keys(self.organisation.users).find((u) => self.organisation.users[u] == "leadRespondent");
					if (leadRespondent) {
						self.leadRespondent = leadRespondent;
					}
				}
			},
		},
	},
	created() {
		this.$store.dispatch("categories/fetchById", "service");
		this.$store.dispatch("users/openDBChannel");
		this.$store.dispatch("assessments/openDBChannel");
	},
	destroyed() {
		this.$store.dispatch("users/closeDBChannel");
		this.$store.dispatch("assessments/closeDBChannel");
	},
};
</script>

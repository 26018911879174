<template>
	<v-snackbar v-model="saved" color="primary">{{$t("messages.saved")}}</v-snackbar>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "Saved", 
		props: {
			value: {}
		},
		data: () => {
			return {
				saved: false
			}
		},
		watch: {
			saved: {
				handler(value){
					this.$emit("input", value )
				}
			}, 
			value: {
				immediate: true, 
				handler(value){

					if( this.saved !== value ){
						this.saved = value
					}
				}
			}
		}, 
	}
// </script>"
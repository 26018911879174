<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		:mini-variant="mini"
		permanent
		style="z-index: 100"
	>
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item
				v-for="item in displayItems"
				:key="item.title"
				:link="item.link"
				v-bind="item"
			>
			</nav-item>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<logout v-if="loggedIn"></logout>
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
import LoggedIn from "@/components/auth/LoggedIn";
import Login from "@/components/auth/Login";
import Logout from "@/components/auth/Logout.vue";
import NavItem from "@/components/navigation/Item";
export default {
	name: "Sidebar",
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
		displayItems() {
			var loggedIn = this.loggedIn;
			return this.items.filter((a) => {
				return !a.loggedIn || loggedIn;
			});
		},
		items() {
			return [
				{ text: "Home", icon: "mdi-home", link: "/" },
				{
					text: "Config",
					icon: "mdi-cog",
					link: "/config",
					loggedIn: true,
				},
				{
					text: this.$store.state.users.name.plural,
					icon: this.$store.state.users.icon,
					link: this.$store.state.users.path,
					loggedIn: true,
				},
				{
					text: "Sections",
					icon: "mdi-file-table-box-multiple-outline",
					link: "/sections",
					loggedIn: true,
				},
				{
					text: "Organisations",
					icon: "mdi-hospital-building",
					link: "/organisations",
					loggedIn: true,
				},
				{
					text: this.$store.state.indicators.name.plural,
					icon: this.$store.state.indicators.icon,
					link: this.$store.state.indicators.path,
					loggedIn: true,
				},
				{
					text: this.$store.state.questionTemplates.name.plural,
					icon: this.$store.state.questionTemplates.icon,
					link: this.$store.state.questionTemplates.path,
					loggedIn: true,
				},
				{
					text: "Categories",
					icon: "mdi-tag-multiple",
					link: "/categories",
					loggedIn: true,
				},
				{
					text: this.$store.state.emailTemplates.name.plural,
					icon: this.$store.state.emailTemplates.icon,
					link: this.$store.state.emailTemplates.path,
					loggedIn: true,
				},
				{
					text: this.$store.state.content.name.plural,
					icon: this.$store.state.content.icon,
					link: this.$store.state.content.path,
					loggedIn: true,
				},
				{
					text: this.$store.state.supportLog.name.plural,
					icon: this.$store.state.supportLog.icon,
					link: this.$store.state.supportLog.path,
					loggedIn: true,
				},
				{
					text: this.$store.state.knowledgeBase.name.plural,
					icon: this.$store.state.knowledgeBase.icon,
					link: this.$store.state.knowledgeBase.path,
					loggedIn: true,
				},
			];
		},
	},
	data() {
		return {
			drawer: true,
			mini: true,
		};
	},
	components: {
		Login,
		LoggedIn,
		Logout,
		NavItem,
	},
};
</script>

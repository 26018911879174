<template>
	<span>	
		<v-btn color="primary" small :to="url">{{$t("buttons.edit")}}</v-btn>
	</span>	
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "EditButton", 
		props: {
			id: { type: String }, // @doc the ID of the object 
			storeModule: { type: String }, // the corresponding vuex module
			path: {  type: String  } // the path for the url - almost always the same as the storeModule
		},
		computed: {
			url(){
				var path = this.path ? this.path : this.storeModule;
				return `/${path}/${this.id}`
			}
		},

	}
// </script>"
<template>
	<div>
		<v-simple-table>
			<thead>
				<tr>
                    <th> </th>
					<th>value</th>
					<th>label</th>
					<th>isScored</th>
					<th>score</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(doc, i) in docs" :key="i">
                    <td>

						<v-icon
							class="mr-5"
							color="red"
							@click="deleteItem(doc.value)"
							>mdi-close-circle</v-icon>
                    </td>
					<td>
						<v-text-field v-model="doc.value"></v-text-field>
					</td>
					<td>
						<v-text-field v-model="doc.text"></v-text-field>
					</td>
					<td>
						<v-checkbox v-model="doc.scored"></v-checkbox>
					</td>
					<td>
						<v-text-field
							v-model="doc.score"
							v-if="doc.scored"
						></v-text-field>
					</td>
					<td>
						<v-icon @click="move(doc.value, 'up')">
							mdi-arrow-up-drop-circle</v-icon
						>
						<v-icon @click="move(doc.value, 'down')">
							mdi-arrow-down-drop-circle
						</v-icon>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		<v-row class="mt-5">
			<v-col>
				<v-text-field
                    v-model="newValue"
					placeholder="Add new"
					@click:append="addNew"
					append-icon="mdi-arrow-right-circle"
					@keydown.enter="addNew"
				></v-text-field>
				<p>
					<v-btn @click="addNoAnswer" color="primary" text>Add N/A</v-btn>
				</p>
			</v-col>
		</v-row>
		
	</div>
</template>

<style lang="less" scoped>
.v-chip {
	cursor: pointer;
}
</style>

<script>
export default {
	name: "AnswerOptions",
	props: {
		value: { type: Object, default: () => [] }, // @doc the array of values
	},
	data: () => {
		return {
            newValue: "",
			docs: [],
		};
	},
	created() {
		var self = this;
		self.docs = self.mwutils.sortByKey(Object.values(self.value));
	},
    watch: {
        docs: {
            deep: true, 
            handler() {
                this.save();
            }
        }
    },
	methods: {
		addNew() {
            this.docs.push( {
                text: this.newValue,
                scored: false,
                score: 0,
                position: this.docs.length,
                value: this.mwutils.strToKey(this.newValue)
            } )
            this.newValue = "";
		},
		addNoAnswer(){
			this.docs.push( {
                text: "No Answer",
                scored: false,
                score: 0,
                position: this.docs.length,
                value: "no-answer"
            } )
		},
		deleteItem(id) {
			var index = this.docs.indexOf(id);
			this.docs.splice(index, 1);
			// this.$emit("input", this.docs);
		},
		move(id, direction) {
            var self = this;
            var order = self.docs.map( a => a.value ); 
			self.mwutils.moveItemInArray(id, order, direction);
            var neworder = order.map( b => {
                return self.docs.find( c => c.value == b );
            })
            self.docs = neworder;
        },
		save() {
			const self = this;
            self.docs.forEach( (v, i) => {
                v.position = i;
            } )
			var options = Object.fromEntries(self.docs.map((a) => [a.value, a]));
			self.$emit("input", options);
		},
	},
};
</script>

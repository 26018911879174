export default {     
	firestorePath: "sections",
	firestoreRefType: "collection",
	moduleName: "sections",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.questions ){
				dispatch("questions/fetchByIds", doc.questions)
			}

			return updateStore(doc)
		},
	},	
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

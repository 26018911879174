<template>
	<v-sheet>
        <MwList storeModule="indicators" :columns="headers">
        </MwList>
		<import :defaultFields="importFields" @submit="go"></import>
    </v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
import Import from '@/components/buttons/Import.vue';
export default {
	name: "Indicators",
    data: () => {
        return {
            filtered: false, 
			importFields: "title, question_title"
        }
    },
	computed: {
		headers() {
			return [
				{value: "title", text: "Title", default: true},
			];
		},
	},
	components: {
		MwList, Import
	},
	methods: {
		go(data){
			var self = this;
			data.forEach( item => {

				self.$store.dispatch('indicators/insert', item)
			})
		}
	}
};
//
</script>
"

<template>
	<v-sheet>
		<v-select
			chips
			multiple
			:items="userItems"
			:value="leadRespondents"
			label="Lead Respondent"
			@change="(V) => changeUser(V, 'leadRespondent')"
		></v-select>
		<v-select
			chips
			multiple
			:items="userItems"
			:value="delegates"
			label="Delegates"
			@change="(V) => changeUser(V, 'delegate')"
		></v-select>
		<v-data-table :search="search" :items="rows" :headers="columns">
			<template v-slot:item.delegateName="{ item }">
				<v-select
					:items="delegateItems"
					:value="item.delegate"
					@change="(V) => changeDelegate(V, item)"
				></v-select>
			</template>
			<template v-slot:item.sectionName="{ item }">
				<b>{{item.sectionName}}</b>
			</template>
			<template v-slot:item.status="{ item }">
				<v-select
					:items="['delegated', 'complete', 'returned', 'not_started', 'in_progress']"
					:value="item.status"
					@change="(V) => changeStatus(V, item)"
				></v-select>
			</template>
		</v-data-table>
		<v-text-field v-model="search" label="Search"></v-text-field>
	</v-sheet>
</template>

<script>
export default {
	name: "EditAssessment",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			search: "",
		};
	},
	computed: {
		assessment() {
			return this.$store.state.assessments.data[this.id];
		},
		sectionResponses() {
			const self = this;
			let responses = Object.values(self.$store.state.sectionResponses.data);
			responses = responses.filter((a) => a.assessment == self.id);
			return Object.fromEntries(responses.map((r) => [r.id, r]));
		},
		sections() {
			return this.$store.state.sections.data;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		users() {
			const self = this;
			let id = self.assessment.organisation;
			let users = Object.values(self.$store.state.users.data);
			users = users.filter(
				(a) => a.organisations && a.organisations.includes(id)
			);
			return Object.fromEntries(users.map((u) => [u.id, u]));
		},
		userItems() {
			return this.mwutils.itemsArray(this.users, "displayName");
		},
		delegates() {
			const self = this;
			return Object.keys(self.assessment.users).filter(
				(a) => self.assessment.users[a] == "delegate"
			);
		},
		delegateItems() {
			const self = this;
			return self.delegates.map((d) => {
				return { value: d, text: self.users[d] ? self.users[d].displayName : d };
			});
		},
		leadRespondents() {
			const self = this;
			return Object.keys(self.assessment.users).filter(
				(a) => self.assessment.users[a] == "leadRespondent"
			);
		},
		rows() {
			const self = this;
			let responses = this.assessment.responses;
			return Object.keys(responses).flatMap((section) => {
				if (typeof responses[section] == "string") {
					let id = responses[section];
					return self.makeRow(section, id, false);
				} else {
					return Object.keys(responses[section]).map((resp) => {
						return self.makeRow(section, responses[section][resp], resp);
					});
				}
			});
		},
		columns() {
			return [
				{ text: "Section", value: "sectionName" },
				{ text: "Service", value: "category" },
				{ text: "Completion Rate", value: "rate" },
				{ text: "Status", value: "status" },
				{ text: "Delegate", value: "delegateName" },
			];
		},
	},
	methods: {
		makeRow(sectionId, responseId, cat) {
			const self = this;
			let response = self.sectionResponses[responseId];
			let section = self.sections[sectionId];
			let categoryOption = self.categoryOptions[cat];
			let delegate = "";
            if( response ) {
                delegate = Object.keys(response.users).find(
                    (a) => response.users[a] == "delegate"
                );
            }
			let user = self.users[delegate];
			return {
                id: responseId,
				sectionName: section ? section.name : "",
				rate: response ? `${response.completion_rate.toFixed()}%` : "0%",
				status: response ? response.status : "",
				delegate: delegate,
				delegateName: delegate ? user.displayName : "",
				category: categoryOption ? categoryOption.name : "",
			};
		},
		changeUser(values, key) {
			const self = this;
			const existing = Object.keys(self.assessment.users).filter(
				(a) => self.assessment.users[a] == key
			);
			const removed = existing.filter((e) => !values.includes(e));
			const added = values.filter((e) => !existing.includes(e));
			removed.forEach((v) => {
				self.$store.dispatch("assessments/delete", `${self.id}.users.${v}`);
			});
			added.forEach((v) => {
				self.$store.dispatch("assessments/patch", {
					id: self.id,
					users: { [v]: key },
				});
			});
		},
        changeDelegate( value, item ){
            if( item.delegate ){
                this.$store.dispatch("sectionResponses/delete", `${item.id}.users.${item.delegate}`)
            }
            this.$store.dispatch("sectionResponses/patch", {id: item.id, users: {[value]: "delegate" } })
        }, 
        changeStatus( status, item ){
            this.$store.dispatch("sectionResponses/patch", {id: item.id, status })
        }
	},
	created() {
		this.$store.dispatch("sections/openDBChannel");
		this.$store.dispatch("sections/openDBChannel");
		this.$store.dispatch("categories/fetchById", "service");

		this.$store.dispatch("sectionResponses/openDBChannel", {
			clauses: { where: [["assessment", "==", this.id]] },
		});
	},
};
</script>

<template>
	<span>
		<v-item-group v-model="selected" multiple v-if="options.length">
			<v-item
				v-slot="{ active, toggle }"
				v-for="(option, i) in options"
				:key="i"
				:value="option.id"
			>
				<v-chip
					small
					class="mr-2"
					@click="toggle"
					:color="active ? 'green' : 'grey'"
					>{{ option.name }}</v-chip
				>
			</v-item>
		</v-item-group>
	</span>
</template>

<script>
export default {
	name: "EditConfigMultiple",
	props: {
		value: { type: Array },
		category: { type: String },
	},
	data: () => {
		return {
			selected: [],
		};
	},
	computed: {
		options() {
			const self = this;
			if (!this.category) {
				return;
			}
			var category = this.$store.state.categories.data[this.category];
			if (!category) {
				return [];
			}
			var options = category.options
				.map((a) => {
					return self.$store.state.categoryOptions.data[a];
				})
				.filter((a) => a);
			return options;
		},
	},
	watch: {
		selected() {
			var rules = [];
			var self = this;
			self.selected.forEach((option) => {
				rules.push({
					category: self.category,
					operator: "==",
					value: option,
				});
			});
			self.$emit("input", rules);
		},
	},
	created() {
		if (this.value) {
			this.selected = this.value.map((a) => {
				return a.value;
			});
		}
	},
};
</script>

export default {
	name: {
		value: "name",
		component: "v-text-field",
		default: "",
		display: true,
		label: "misc.name",
	},
	users: {
		value: "users",
		default: {},
		display: false,
		label: "organisations.fields.users",
	},
	assessment: {
		value: "assessment",
		display: false,
	},
	test_data: {
		value: "test_data",
		display: true,
		default: false,
		label: "organisations.fields.test_data",
		component: "v-checkbox",
	},
	service: {
		value: "service",
		default: [],
		display: true,
	},
};

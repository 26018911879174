import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";

import { createStore } from 'vuex-extensions'
import VuexEasyFirestore from "vuex-easy-firestore";

Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import modules from "./moduleLoader.js"


const vxf = VuexEasyFirestore(
	Object.values(modules),
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils =  {
	actions: {
		fetchByIds: ({dispatch}, ids) => {
			if(!ids ){
				return;
			}
			ids.forEach( id => {
				dispatch("fetchById", id ); 
			})
		} 
	}
}
const storeData = {
	actions,
	getters, 
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store =  createStore(Vuex.Store, storeData );


export default store;

export default {     
	firestorePath: "organisations",
	firestoreRefType: "collection",
	moduleName: "organisations",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published", 
			test_data: false
		},
		insertHook: function (updateStore, doc, store) { 
			store.dispatch("assessments/insert", {organisation: doc.id});
			return updateStore(doc) 
		},

	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

export default {
	firestorePath: "knowledgeBase",
	firestoreRefType: "collection",
	moduleName: "knowledgeBase",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-brain",
		name: {
			single: "Knowledge", 
			plural: "Knowledge"
		},
		path: "/knowledge"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		},
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};

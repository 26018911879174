<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="categories"
			:model="model"
			:prefill="prefill"
		>			
			<template v-slot:options="slotProps" v-if="id">
				<mw-select-or-create
					v-model="slotProps.data.options"
					storeModule="categoryOptions"
					
				>						
				</mw-select-or-create>
			</template> 

		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Category from "@//models/Category";
import CategoryOption from "@//models/CategoryOption";
import MwEdit from "@c/ui/MwEdit";
import MwSelectOrCreate from '@c/ui/MwSelectOrCreate.vue';

export default {
	name: "EditCategory",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
		prefill: { type: Object }, //  key/value pairs of data to prefill into the form where required
	},
	computed: {
		model() {
			return Category;
		},
		categoryOption(){
			return CategoryOption
		}
	},
	components: {
		MwEdit, 
		MwSelectOrCreate,
	},
};
//
</script>
"

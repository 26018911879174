export default {
    question_template: {
        value: "question_template", 
        display: true, 
    },
    indicator: {
        value: "indicator", 
        display: true
    },
    content: {
        value: "content", 
        display: true
    },
    conditions: {
        value: "conditions", 
        display: true
    },
    help_text: {
        value: "help_text", 
        display: true
    },
    hide_polling: {
        value: "hide_polling", 
        display: true
    },
    analytics_category: {
        value: "analytics_category", 
        display: true
    },
    custom_id: {
        value: "custom_id", 
        display: true
    },
    hide_content: {
        value: "hide_content", 
        display: true, 
        label: "questions.fields.hideTitle",
        component: "v-checkbox"
    },
}